<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2024-02-07 09:31:02
 * @LastEditors: wenboliu
 * @LastEditTime: 2024-05-04 16:38:48
-->
<!-- 播放 -->
<template>
  <div
    v-loading="currentLoading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    ref="videoCons"
    style="width:100%;height:100%;position: relative;"
    class="videCOns" @mousemove="_moveEvent">
    <video @loadeddata="loadeddata"  ref="videoEL" @timeupdate="upDate" :src="src" style="width:100%;height:100%" @click="play"></video>
    <div class="watermark-outer" v-if="showWatermark">
    <div class="watermark" v-for="i in 200" :key="i">
      <div>{{$store.state.userList.userName}}-{{$store.state.userList.deptName}}</div>
    </div>
  </div>
    <div class="screen-play-btn" @click.stop="play">
      <img v-if="!isPlay" style="width: 68px;height: 68px;" src="@/assets/img/stopBig.png">
    </div>
    <div class="controsCon" :style="{opacity:opacity}">
    <!-- 操作按钮---start -->
      <div class="controsCon-top">
        <!--播放、暂停按钮--开始 -->
        <div class="playBtn" @click.stop="play">
            <img v-if="!isPlay" style="width: 23px;height: 23px;" src="@/assets/img/stopSmall.png">
            <img v-if="isPlay" style="width: 23px;height: 23px;" src="@/assets/img/playsSmall.png">
        </div>
        <!--播放、暂停按钮--结束 -->
        <!-- 播放实时时间-开始 -->
        <div class="play-time">
          <span>{{ currentPlayedTime }} / {{ allTime }}</span>
          <span></span>
        </div>
        <!-- 播放实时时间-结束 -->
        <!-- 音量--开始 -->
        <!-- <div class="volum">
            <img style="height:23px;width: 23px;" src="https://generate.yingshevip.com/default/2024/02/23/14/3b0987f8b51f48b7b69cbbe34b2162a1.png">
            <div class="vCon">
                <el-slider @input="volum" v-model="volums" vertical style="height:100%;"></el-slider>
            </div>
        </div> -->
        <!-- 音量--结束 -->
        <!-- 选择倍速--开始 -->
        <div class="speedStyle">
          <el-dropdown placement="top" @command="_handleCommand">
            <span class="el-dropdown-link" style="color:#fff;">
              倍速
              <i class="el-icon-arrow-up el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" :style="{'--themeColor':themeColor}">
              <div v-for="(item,index) in videoSpeedList" :key="index">
                <el-dropdown-item :class="item.value == videoSpeed?'speedActiveStyle':'speedDefaultStyle'" :command="item.value">{{item.value}}x</el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- 选择倍速--结束 -->
        <!-- 全屏--开始 -->
        <div class="allCon" @click="scaleEl">
            <img v-if="!isFullScreen" style="width: 20px;height: 20px;" src="@/assets/img/scaleToBig.png">
            <img v-if="isFullScreen" style="width: 20px;height: 20px;" src="@/assets/img/scaleToBig.png">
        </div>
        <!-- 全屏结束 -->
      </div>
      <!-- 操作按钮---end -->
      <!-- 进度条--开始 -->
      <div class="controsCon-bottom-bar">
        <div class="jdt">
          <!-- 未完成的视频不可拖动 -->
          <el-slider
            v-model="percent"
            v-if="currentLeftContent.statusValue!='已完成'&&currentLeftContent.learnedStatus!=2"
            disabled
            :show-tooltip="false">
          </el-slider>
          <!-- 已完成的视频可拖动 -->
          <el-slider
            :key="refreshLoadedVideo"
            v-else-if="loadedVedio"
            v-model="time"
            :max="max"
            @change="sliderChange"
            :show-tooltip="false">
          </el-slider>
          <!-- 进度条 -->
        </div>
      </div>
      <!-- 进度条--结束 -->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    currentLeftContent: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      themeColor: this.$store.state.settings.theme,
      videoTotalTime: 0, // 视频返回的总毫秒数
      currentPlayTime: 0, // 当前播放视频的总毫秒数
      opacity: 0, // 当鼠标移入视频展示下方播放按钮及进度条
      timer: null, /// 当鼠标离开4秒之后隐藏进度条（定时器）
      percent: 0, // 进度条的进度
      video: null, // 当前视频标签dom
      isPlay: false, // 是否为播放状态
      volums: 30, // 音量
      prefVom: 0.3,
      isFullScreen: false, // 是否全屏
      currentPlayedTime: '00:00', // 当前已播放时间
      allTime: '00:00', // 视频总播放时间
      noRecordBehavior: false,
      loadedVedio: false,
      time: 0,
      max: 100,
      refreshLoadedVideo: new Date().getTime(),
      currentLoading: true,
      showWatermark: false,
      videoSpeedList: [
        {
          id: 0,
          value: 2.0
        }, {
          id: 1,
          value: 1.5
        }, {
          id: 2,
          value: 1.25
        }, {
          id: 3,
          value: 1.0
        }, {
          id: 4,
          value: 0.5
        }
      ],
      videoSpeed: 1.0
    }
  },
  created () {
    this.currentLeftContent.mediaProgress = Number(this.currentLeftContent.mediaProgress)
  },
  mounted () {
    this.video = this.$refs.videoEL
    this.video.volume = this.prefVom
    this.loadedVedio = true
    this.currentPlayedTime = this._convertTimeToHMS(this.currentLeftContent.mediaProgress * 1000)// 将毫秒数转成时分秒
    this.video.currentTime = Number(this.currentLeftContent.mediaProgress)
    this.percent = (this.video.currentTime / this.video.duration) * 100// 当前进度条
    this.themeColor = this.$store.state.settings.theme
    this.video.playbackRate = Number(this.videoSpeed)
  },
  watch: {
    // 视频暂停时--去更新，调用视频更新接口
    isPlay (newValue, oldValue) {
      if (newValue === false) {
        this.$emit('updateVideoPace', Number(this.video.currentTime))
      }
    },
    'time' (newValue, oldValue) {
      this.time = newValue
      if (this.time === this.video.duration && this.isPlay) {
        this.play()
      }
    },
    'percent' (newValue, oldValue) {
      if (newValue === this.video.duration && this.isPlay) {
        this.play()
      }
    }
  },
  beforeDestroy () {
    this.$emit('updateVideoPaceOld', Number(this.video.currentTime))
    this.video.pause()
  },
  methods: {
    // formatTooltip (val) {
    //   return Number(this.currentLeftContent.mediaProgress).toFixed(2)
    // },
    loadeddata () {
      this.currentLoading = true
      // 初始化视频信息
      const durat = this.video.duration// 视频返回的总秒数
      this.videoTotalTime = Number(durat || 0) * 1000// 变成毫秒数
      this.allTime = this._convertTimeToHMS(this.videoTotalTime)// 将毫秒数转成时分秒
      console.log(this.currentLeftContent.mediaProgress, 'this.currentLeftContent.mediaProgress')
      this.time = Number(this.currentLeftContent.mediaProgress)
      this.max = this.video.duration
      this.refreshLoadedVideo = new Date().getTime()
      this.currentLoading = false
    },
    // 监听滑块值
    sliderChange (value) {
      console.log(value, this.time, 'value滑块值')
      if (value) {
        this.video.currentTime = value
        this.time = value
      }
    },
    _moveEvent () {
      this.opacity = 1
      // 当鼠标移入视频展示下方播放按钮及进度条
      // 当鼠标离开4秒之后隐藏进度条（定时器）
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.opacity = 0
      }, 4000)
    },
    // 全屏和取消全屏
    scaleEl () {
      const el = this.$refs.videoCons
      const isfull = document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || false
      if (!isfull) {
        this.isFullScreen = true
        this.showWatermark = true
        if (el.requestFullscreen) {
          el.requestFullscreen()
        } else if (el.mozRequestFullScreen) {
          el.mozRequestFullScreen()
        } else if (el.webkitRequestFullscreen) {
          el.webkitRequestFullscreen()
        } else if (el.msRequestFullscreen) {
          el.msRequestFullscreen()
        }
      } else {
        this.isFullScreen = false
        this.showWatermark = false
        if (document.exitFullScreen) {
          document.exitFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    upDate () {
      const cur = this.video.currentTime
      const dur = this.video.duration
      this.percent = (cur / dur) * 100// 当前进度条
      this.time = this.video.currentTime// 可拖动的进度条跟视频长度保持一致
      this.currentPlayTime = Number(cur || 0) * 1000
      this.currentPlayedTime = this._convertTimeToHMS(this.currentPlayTime)// 将毫秒数转成时分秒
      if (this.currentPlayTime === this.videoTotalTime) {
        this.isPlay = false
      }
      // this.$emit('updateVideoPace', Number(this.video.currentTime))
    },
    play () {
      this.$parent.leaveCurrentPage = false
      this.isPlay = !this.isPlay
      this.isPlay ? this.video.play() : this.video.pause()
    },
    volum (e) {
      this.volums = e
      this.prefVom = e / 100
      this.video.volume = this.prefVom
    },
    // 将毫秒转成时分秒
    _convertTimeToHMS (tempTime) {
      let formatTime = ''
      const hours = parseInt((tempTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = parseInt((tempTime % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = parseInt((tempTime % (1000 * 60)) / 1000)
      const tempHours = hours > 9 ? hours : '0' + hours
      const tempMin = minutes > 9 ? minutes : '0' + minutes
      const tempSec = seconds > 9 ? seconds : '0' + seconds
      if (tempHours != 0) {
        formatTime = (`${tempHours}:${tempMin}:${tempSec}`).slice(0, 8)
      } else {
        formatTime = (`${tempMin}:${tempSec}`).slice(0, 5)
      }
      return formatTime
    },
    _handleCommand (command) {
      this.videoSpeed = command
      this.video.playbackRate = Number(this.videoSpeed)
    }
  }
}
</script>
<style  scoped lang="scss">
/deep/.el-slider__runway{
  background-color: rgba(255,255,255,0.5);
  margin: 0px;
  height: 2px;
}
/deep/.el-slider__button-wrapper{
  top: -18px;
}
/deep/.el-slider__runway.disabled .el-slider__bar{
  background-color: #0089FF!important;
}
/deep/.el-slider__bar{
  background-color: #0089FF!important;
  height: 2px;
}
/deep/.el-slider__button{
  width: 8px;
  height: 8px;
  background-color: #0089FF!important;
  border: none!important;
}
/deep/.el-slider__runway.disabled .el-slider__button{
  border-color: #0089FF!important;
}
.videCOns{
  position: relative!important;
}
.controsCon{
    background-color:rgba(0,0,0,0.3);
    position: absolute;
    width: 100%;
    bottom:0px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    // 上边操作按钮
    .controsCon-top{
      position: relative;
      display: flex;
      align-items: center;
      flex: 1;
      width: 100%;
    }
    // 下边进度条样式
    .controsCon-bottom-bar{
      flex: 1;
      width: 100%;
      padding-bottom: 10px;
    }
}
.screen-play-btn{
  position: absolute;
  top: 44%;
  left: 46%;
}
.playBtn{
    height: 30px;
    width:30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right:16px;
    position: relative;
    margin-left: 16px;
}
.playIcon{
    font-size: 30px;
}
.play-time{
  color: #fff;
  font-size: 12px;
  span{
    opacity: 1;
  }
}
.volum{
    height: 30px;
    width: 40px;
    position: absolute;
    top: 0;
    right: 46px;
    cursor: pointer;
}
.vCon{
    position: absolute;
    top:-150px;
    left: 0;
    height: 150px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}
.volum:hover .vCon{
    opacity: 1;
}
.vCon>>> .el-slider__runway{
    background-color: #ccc;
}
.jdt{
    height: 10px;
    width: 100%;
    padding:0 16px;
    box-sizing: border-box;
    margin:0 auto;
    margin-top:10px;
    /deep/.el-progress-bar__outer{
      height: 4px!important;
    }
}
.speedStyle{
  height: 30px;
  width: 56px;
  position: absolute;
  top:8px;
  right: 50px;
  cursor: pointer;
}
.allCon{
    height: 30px;
    width: 40px;
    position: absolute;
    top:8px;
    right: 0px;
    cursor: pointer;
}
.watermark-outer {
  position: absolute;
  top: 10px;
  left: 50px;
  width: 5500px;
  height: 2000px;
  z-index: 10000;
  pointer-events: none;
}

.watermark {
  margin: 10px;
  width: 450px;
  float: left;
  height: 160px;
  color: rgba(154, 152, 152, 0.3);
  transform: rotate(-20deg);
  font-size: 15px;
}
</style>
